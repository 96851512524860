body {
  background-color: #121212;
  color: white;
}

.center-container {
  position: fixed;
  width: 100%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

/* HEADER */

.logo {
  width: 40px;
}

.header {
  height: 55px;
  background-color: rgba(255, 255, 255, 0.12);
  color: white;
  display: flex;
  align-items: center;
  justify-content: left;
  width: 100%;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  position: fixed;
}

.header .logo {
  margin-left: 20px;
  width: 40px;
}

.header .vl {
  margin-left: 10px;
  border-left: 3px solid white;
  height: 35px;
}

.header h1 {
  font-weight: bolder !important;
  margin-left: 20px;
  background: -webkit-linear-gradient(#eee, #333);
  background: -webkit-linear-gradient(
    25deg,
    rgba(2, 64, 212, 1) 0%,
    rgba(1, 111, 255, 1) 21%,
    rgba(0, 153, 255, 1) 62%,
    rgba(0, 243, 255, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* BOTTOM NAVIGATION */

.nav {
  height: 50px;
  background-color: rgba(255, 255, 255, 0.12);
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  display: flex;
  align-items: center;
  overflow: hidden;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 99999999999999;
}

.nav .nav-menu {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.nav .nav-item {
  justify-content: space-around;
  cursor: pointer;
}

.nav .nav-link {
  color: white;
}

.nav .nav-link.active {
  color: rgba(0, 223, 252);
}

/* LOADING ANIMATION */

.dots-container {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.dots {
  width: 3em;
  height: 3em;
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  align-items: center;
}

.dots > div {
  width: 0.5em;
  height: 0.5em;
  background-color: #3cefff;
  border-radius: 50%;
  animation: fade 1.5s alternate ease-in-out infinite;
}

.dots > div:nth-of-type(2),
.dots > div:nth-of-type(4) {
  animation-delay: 0.25s;
}

.dots > div:nth-of-type(3),
.dots > div:nth-of-type(5),
.dots > div:nth-of-type(7) {
  animation-delay: 0.5s;
}

.dots > div:nth-of-type(6),
.dots > div:nth-of-type(8) {
  animation-delay: 0.75s;
}

.dots > div:nth-of-type(9) {
  animation-delay: 1s;
}

@keyframes fade {
  to {
    opacity: 0.2;
  }
}
